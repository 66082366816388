import { css } from "goober";

export const ContentContainer = () => css`
  display: grid;
  grid-auto-rows: auto;
  gap: var(--sp-4);
  container-name: content-container;

  header {
    background: var(--shade7);
    border-top-left-radius: var(--br-lg);
    border-top-right-radius: var(--br-lg);
    border-bottom: 1px solid var(--shade3-25);
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: var(--sp-6);
    color: var(--shade1);
  }

  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-4);

    @container content-container (width <= 575px) {
      grid-template-columns: 1fr !important;
    }
  }

  .TierIcon {
    width: var(--sp-6_5) !important;
    height: var(--sp-6_5) !important;
  }

  .tailored-emblems {
    height: 100%;
  }

  .emblems-table,
  .tailored-emblems {
    header {
      padding: var(--sp-4) var(--sp-5);
    }
  }

  .emblems-table .contents {
    overflow-y: scroll;
    max-height: 230px;

    .value {
      width: 46px;
      justify-content: center;
      display: flex;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--shade4);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .no-emblem-traits {
    display: flex;
    align-items: center;

    header {
      padding: var(--sp-5) var(--sp-6);
      border-top-left-radius: var(--br-lg);
      border-bottom-left-radius: var(--br-lg);
      border-top-right-radius: 0;
      border-right: 1px solid var(--shade3-25);
    }

    > div {
      padding: var(--sp-5) var(--sp-6);
      justify-content: space-between;
      width: 80%;
    }

    .icons {
      display: flex;
      gap: var(--sp-2);
    }

    .tip {
      color: var(--shade1);
      width: 40%;
      text-align: end;
    }

    @container content-container (width <= 575px) {
      flex-direction: column;

      header {
        width: 100%;
        text-align: center;
      }

      > div {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: var(--sp-3);
      }

      .tip {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
