import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { CombinedItemImage } from "@/game-tft/CommonComponents.jsx";
import { getCurrentSet, isFutureSet } from "@/game-tft/constants.mjs";
import {
  emblemChanceByTraitCount,
  tailoredEmblemsGranted,
} from "@/game-tft/constants-tome.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import { TableRow } from "@/game-tft/GuideExp.style.jsx";
import { pips } from "@/game-tft/GuideReRoll.jsx";
import { PercentBreakdown } from "@/game-tft/GuideReRoll.style.jsx";
import { URLS } from "@/game-tft/guides-constants.mjs";
import { ContentContainer } from "@/game-tft/GuideTome.style.jsx";
import ItemTooltip from "@/game-tft/ItemToolTip.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import TraitTooltip from "@/game-tft/TraitTooltip.jsx";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import TFTUnitIcon from "@/inline-assets/tft-unit.svg";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const noEmblemCols = [0, 1, 2, 3, 4, 5];

const FORMAT = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
  style: "percent",
};

const TomeGuide = () => {
  const { searchParams } = useRoute();
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const set = useSetByParam(true);
  const itemFilters = getFilters(searchParams, "items", isFutureSet(set));
  const itemStats = state.tft.stats.items?.[itemFilters]?.[set];
  const locale = getLocale();

  const emblems = useMemo(
    () =>
      itemStats
        ? Object.entries(itemStats)
            .filter(([_, item]) => item.item_type === "emblem")
            .sort(
              (a, b) =>
                a[1].tier +
                a[1].avg_placement / 10 -
                (b[1].tier + b[1].avg_placement / 10),
            )
        : [],
    [itemStats],
  );

  const itemStaticData = state.tft[set]?.items;
  const traits = state.tft[set]?.traits;

  const nonEmblems = useMemo(() => {
    if (!itemStaticData || !traits) return [];

    const emblemTraits = Object.entries(itemStaticData)
      .filter(([_, item]) => item?.trait)
      .map(([_, item]) => item.trait);

    return Object.entries(traits)
      .filter(([key, _]) => !emblemTraits.includes(key))
      .map(([key, _]) => key);
  }, [itemStaticData, traits]);

  return (
    <PageContainer>
      <PageHeader
        title={t("tft:guidesTomeOfTraits", "Tome of Traits - Guides")}
        links={URLS}
      />
      <div className={ContentContainer()}>
        <Card className="description type-body1">
          <p>
            {t(
              "tft:tomeOfTraitsAwardsEmblems",
              "Tome of Traits awards Emblems according to your existing traits. A greater total number of traits increases your likelihood of obtaining one of them.",
            )}
          </p>
          <p>
            {t(
              "tft:byUsingDifferentUnitsYouCan",
              "By using different units, you can manipulate your Tome of Traits odds, but this might weaken your board. For better odds, add No-Emblem Traits during PvE rounds to increase trait count and narrow down emblem choices.",
            )}
          </p>
          <p>
            {t(
              "tft:noteTraitsCountForTomeOfTraitsAfterPlaying",
              "Note: Traits count for Tome of Traits after playing one round with your board (including PvE rounds).",
            )}
          </p>
        </Card>
        <div
          className="section"
          style={{ gridTemplateColumns: emblems?.length ? "1fr 1fr" : "1fr" }}
        >
          <Card padding="0" className="tailored-emblems">
            <header>
              {t(
                "tft:numberOfTailoredEmblemsGranted",
                `Number of Tailored Emblems Granted`,
              )}
            </header>
            <TableRow>
              <div className="col">
                <span className="type-caption">
                  {t("tft:totalTraits", "Total Traits")}
                </span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("tft:tailoredEmblems", "Tailored Emblems")}
                </span>
              </div>
            </TableRow>
            {tailoredEmblemsGranted.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    <TFTUnitIcon />
                    {t("tft:_traits", "{{count}} Traits", {
                      count: row.traits,
                    })}
                  </div>
                  <div className="type-body2 col value">
                    {t("tft:_tailored", "{{count}} Tailored", {
                      count: row.emblems,
                    })}
                  </div>
                </TableRow>
              );
            })}
          </Card>
          {emblems?.length ? (
            <Card padding="0" className="emblems-table">
              <header>
                {t("tft:bestEmblemsToMake", "Best Emblems to Make")}{" "}
              </header>
              <div className="contents">
                <TableRow>
                  <div className="col">
                    <span className="type-caption">
                      {t("tft:emblem", "Emblem")}
                    </span>
                  </div>
                  <div className="col">
                    <span className="type-caption">
                      {t("common:tier", "Tier")}
                    </span>
                  </div>
                  <div className="col">
                    <span className="type-caption">
                      {t("tft:pickRate", "Pick Rate")}
                    </span>
                  </div>
                  <div className="col">
                    <span className="type-caption">
                      {t("common:avgPlace", "Avg. Place")}
                    </span>
                  </div>
                </TableRow>
                {emblems.map((row, i) => {
                  const [key, item] = row;
                  const TierIcon = getTierIcon(item.tier);
                  return (
                    <TableRow key={i}>
                      <div className="col type-body2 title">
                        <ItemTooltip item={key}>
                          <CombinedItemImage
                            src={StaticTFT.getItemImage(key, set)}
                          />
                        </ItemTooltip>
                      </div>
                      <div className="type-body2 col value">
                        {item.tier ? (
                          <img
                            src={TierIcon}
                            width="28"
                            height="28"
                            className="TierIcon"
                          />
                        ) : null}
                      </div>
                      <div
                        className="type-body2 col value"
                        style={{
                          color: getWinRateColor(item.pick_rate, 0.02),
                        }}
                      >
                        {item.pick_rate
                          ? `${(item.pick_rate * 100).toFixed(1)}%`
                          : ""}
                      </div>
                      <div
                        style={{
                          color: getWinRateColor(item.avg_placement, 4, true),
                        }}
                        className="type-body2 col value"
                      >
                        {item.avg_placement.toLocaleString(locale, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                      </div>
                    </TableRow>
                  );
                })}
              </div>
            </Card>
          ) : null}
        </div>
        {nonEmblems?.length ? (
          <Card padding="0" className="no-emblem-traits">
            <header className="type-caption--bold">
              <div>{t("tft:noEmblemTraits", "No-Emblem Traits")}</div>
              <div>
                ({t("tft:traitsWithoutEmblems", "Traits without emblems")})
              </div>
            </header>
            <div className="flex">
              <div className="icons">
                {nonEmblems.map((trait) => (
                  <TraitTooltip name={trait} set={set} key={trait}>
                    <TypeIcon
                      name={StaticTFT.getTraitKey(trait, set)}
                      size={32}
                    />
                  </TraitTooltip>
                ))}
              </div>
              <div className="tip type-caption">
                {t(
                  "tft:addingTheseTraitsIncreaseTraitCount",
                  "Adding these traits increase trait count and  narrow down emblem possibilities.",
                )}
              </div>
            </div>
          </Card>
        ) : null}
        <Card padding="0" className={PercentBreakdown()}>
          <div className="content">
            <header className="level">
              <div className="cost level-val type-overline">
                {t("tft:totalTraits", "Total Traits")}
              </div>
              {noEmblemCols.map((amt, i) => (
                <div key={i} className="cost type-overline">
                  <span>
                    {t("tft:amtNoEmblemTraits", "{{amt}} No-Emblem Traits", {
                      amt: amt,
                    })}
                  </span>
                </div>
              ))}
            </header>
            <div className="levels">
              {emblemChanceByTraitCount.map((count) => {
                const [totalTraits, values] = Object.entries(count)[0];
                return (
                  <div key={totalTraits} className="level">
                    <div className="cost level-val type-subtitle2">
                      {t("tft:amtTraits", "{{count}} Traits", {
                        count: totalTraits,
                      })}
                    </div>
                    {values.map((chance, i) => (
                      <div
                        key={i}
                        className="cost type-subtitle2"
                        data-cost={chance}
                        style={{
                          "--percent": chance,
                          "--cost-color": getWinRateColor(chance, 0.2),
                        }}
                      >
                        <span>
                          {chance.toLocaleString(getLocale(), FORMAT)}
                        </span>
                        <div className="pips">
                          {new Array(pips(chance)).fill(0).map((_, i) => (
                            <div key={i} className="pip" />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
      </div>
    </PageContainer>
  );
};

export default TomeGuide;

export function meta(_params, searchParams) {
  let set = searchParams?.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: [
      "tft:meta.tomeOfTraits.title",
      "TFT Tome of Traits - TFT Guides - Set {{set}}",
      { set },
    ],
    description: [
      "tft:meta.tomeOfTraits.description",
      "Unlock Winning Strategies with Tome of Traits in TFT! Optimize Comps, Builds, and Meta Using Key Stats.",
      { set },
    ],
  };
}
